import {
  Airdrop,
  Bill,
  Blur,
  Box2,
  BrifecaseTimer,
  Bubble,
  Calendar1,
  CalendarEdit,
  Cpu,
  Diamonds,
  Kanban,
  KyberNetwork,
  Messages2,
  PenTool2,
  Profile2User,
  ShoppingBag,
  TimerStart,
  UserSquare,
  UserTag,
  Windows,
  Zoom
} from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// assets

// icons
const icons = {
  Academic: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  classes: Diamonds,
  attendance: CalendarEdit,
  students: UserTag,
  timetable: TimerStart,
  core: Box2,
  essential: Bubble,
  advanced: Cpu,
  erp: Blur,
  management: BrifecaseTimer,
  communication: Airdrop,
  technical: PenTool2,
  classroommon: Zoom,
  learning: Windows
};

// ==============================|| MENU ITEMS - Academic ||============================== //

const academic = {
  id: 'group-Academic',
  icon: icons.Academic,
  title: <FormattedMessage id=" Modules" />,

  type: 'group',
  children: [
    {
      id: 'Core',
      title: <FormattedMessage id="Core" />,
      icon: icons.core,
      type: 'item',
      url: '/core-modules/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Essential',
      title: <FormattedMessage id="Essential" />,
      icon: icons.essential,
      type: 'item',
      url: '/essential-modules/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Advanced',
      title: <FormattedMessage id="Advanced" />,
      icon: icons.advanced,
      type: 'item',
      url: '/advanced-modules/dashboard',
      breadcrumbs: false
    },
    {
      id: 'ERP',
      title: <FormattedMessage id="ERP" />,
      icon: icons.erp,
      type: 'item',
      url: '/erp-modules/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Management',
      title: <FormattedMessage id="Management" />,
      icon: icons.management,
      type: 'item',
      url: '/management-modules/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Communication',
      title: <FormattedMessage id="Communication" />,
      icon: icons.communication,
      type: 'item',
      url: '/communication/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Technical',
      title: <FormattedMessage id="Technical" />,
      icon: icons.technical,
      type: 'item',
      url: '/technical/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Live',
      title: <FormattedMessage id="Live Classroom" />,
      icon: icons.classroommon,
      type: 'item',
      url: '/live-classroom/dashboard',
      breadcrumbs: false
    },
    {
      id: 'Learning',
      title: <FormattedMessage id="Learning Management" />,
      icon: icons.learning,
      type: 'item',
      url: '/lms/dashboard',
      breadcrumbs: false
    }
    // {
    //   id: 'Core',
    //   title: <FormattedMessage id="Core" />,
    //   icon: icons.classes,
    //   type: 'collapse',
    //   children: [
    //     {
    //       id: 'teacher',
    //       title: <FormattedMessage id="Teacher" />,
    //       type: 'collapse',
    //       icon: icons.customer,
    //       children: [
    //         {
    //           id: 'teacher-list',
    //           title: <FormattedMessage id="list" />,
    //           type: 'item',
    //           url: '/academics/teacher-list'
    //         },
    //         {
    //           id: 'customer-card',
    //           title: <FormattedMessage id="Lessaon Notes" />,
    //           type: 'item',
    //           url: '/apps/customer/customer-card'
    //         }
    //       ]
    //     },
    //     {
    //       id: 'students',
    //       title: <FormattedMessage id="Students" />,
    //       type: 'collapse',
    //       icon: icons.students,
    //       children: [
    //         {
    //           id: 'student-list',
    //           title: <FormattedMessage id="list" />,
    //           type: 'item',
    //           url: '/academics/student-list',
    //           breadcrumbs: false
    //         }
    //         // {
    //         //   id: 'customer-card',
    //         //   title: <FormattedMessage id="Lessaon Notes" />,
    //         //   type: 'item',
    //         //   url: '/apps/customer/customer-card'
    //         // }
    //       ]
    //     },
    //     {
    //       id: 'classes',
    //       title: <FormattedMessage id="Classes" />,
    //       type: 'collapse',
    //       icon: icons.classes,
    //       children: [
    //         {
    //           id: 'student-list',
    //           title: <FormattedMessage id="list" />,
    //           type: 'item',
    //           url: '/academics/classes',
    //           breadcrumbs: false
    //         },
    //         {
    //           id: 'customer-card',
    //           title: <FormattedMessage id="Subjects" />,
    //           type: 'item',
    //           url: '/academics/subjects'
    //         }
    //       ]
    //     },
    //     {
    //       id: 'attendance-list',
    //       title: <FormattedMessage id="Student Attendance" />,
    //       type: 'item',
    //       icon: icons.attendance,
    //       url: '/academics/attendance',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'timetable-list',
    //       title: <FormattedMessage id="Timetable" />,
    //       type: 'item',
    //       icon: icons.timetable,
    //       url: '/academics/timetable',
    //       breadcrumbs: false
    //     }

    //     // {
    //     //   id: 'class-list',
    //     //   title: <FormattedMessage id="Classes" />,
    //     //   type: 'item',
    //     //   icon: icons.classes,
    //     //   url: '/academics/classes',
    //     //   breadcrumbs: false
    //     // }
    //     // {
    //     //   id: 'chat',
    //     //   title: <FormattedMessage id="Teachers" />,
    //     //   type: 'item',
    //     //   url: '/academics/teachers',
    //     //   icon: icons.chat,
    //     //   breadcrumbs: false
    //     // }
    //     // {
    //     //   id: 'calendar',
    //     //   title: <FormattedMessage id="calendar" />,
    //     //   type: 'item',
    //     //   url: '/apps/calendar',
    //     //   icon: icons.calendar
    //     // },
    //     // {
    //     //   id: 'kanban',
    //     //   title: <FormattedMessage id="kanban" />,
    //     //   type: 'item',
    //     //   icon: icons.kanban,
    //     //   url: '/apps/kanban/board'
    //     // },
    //     // {
    //     //   id: 'customer',
    //     //   title: <FormattedMessage id="customer" />,
    //     //   type: 'collapse',
    //     //   icon: icons.customer,
    //     //   children: [
    //     //     {
    //     //       id: 'customer-list',
    //     //       title: <FormattedMessage id="list" />,
    //     //       type: 'item',
    //     //       url: '/apps/customer/customer-list'
    //     //     },
    //     //     {
    //     //       id: 'customer-card',
    //     //       title: <FormattedMessage id="cards" />,
    //     //       type: 'item',
    //     //       url: '/apps/customer/customer-card'
    //     //     }
    //     //   ]
    //     // },
    //     // {
    //     //   id: 'invoice',
    //     //   title: <FormattedMessage id="invoice" />,
    //     //   url: '/apps/invoice/dashboard',
    //     //   type: 'collapse',
    //     //   icon: icons.invoice,
    //     //   breadcrumbs: false,
    //     //   children: [
    //     //     {
    //     //       id: 'create',
    //     //       title: <FormattedMessage id="create" />,
    //     //       type: 'item',
    //     //       url: '/apps/invoice/create'
    //     //     },
    //     //     {
    //     //       id: 'details',
    //     //       title: <FormattedMessage id="details" />,
    //     //       type: 'item',
    //     //       url: '/apps/invoice/details/1'
    //     //     },
    //     //     {
    //     //       id: 'list',
    //     //       title: <FormattedMessage id="list" />,
    //     //       type: 'item',
    //     //       url: '/apps/invoice/list'
    //     //     },
    //     //     {
    //     //       id: 'edit',
    //     //       title: <FormattedMessage id="edit" />,
    //     //       type: 'item',
    //     //       url: '/apps/invoice/edit/1'
    //     //     }
    //     //   ]
    //     // },
    //     // {
    //     //   id: 'profile',
    //     //   title: <FormattedMessage id="profile" />,
    //     //   type: 'collapse',
    //     //   icon: icons.profile,
    //     //   children: [
    //     //     {
    //     //       id: 'user-profile',
    //     //       title: <FormattedMessage id="user-profile" />,
    //     //       type: 'item',
    //     //       url: '/apps/profiles/user/personal',
    //     //       breadcrumbs: false
    //     //     },
    //     //     {
    //     //       id: 'account-profile',
    //     //       title: <FormattedMessage id="account-profile" />,
    //     //       type: 'item',
    //     //       url: '/apps/profiles/account/basic'
    //     //     }
    //     //   ]
    //     // },

    //     // {
    //     //   id: 'e-commerce',
    //     //   title: <FormattedMessage id="e-commerce" />,
    //     //   type: 'collapse',
    //     //   icon: icons.ecommerce,
    //     //   children: [
    //     //     {
    //     //       id: 'products',
    //     //       title: <FormattedMessage id="products" />,
    //     //       type: 'item',
    //     //       url: '/apps/e-commerce/products'
    //     //     },
    //     //     {
    //     //       id: 'product-details',
    //     //       title: <FormattedMessage id="product-details" />,
    //     //       type: 'item',
    //     //       url: '/apps/e-commerce/product-details/1',
    //     //       breadcrumbs: false
    //     //     },
    //     //     {
    //     //       id: 'product-list',
    //     //       title: <FormattedMessage id="product-list" />,
    //     //       type: 'item',
    //     //       url: '/apps/e-commerce/product-list',
    //     //       breadcrumbs: false
    //     //     },
    //     //     {
    //     //       id: 'add-new-product',
    //     //       title: <FormattedMessage id="add-new-product" />,
    //     //       type: 'item',
    //     //       url: '/apps/e-commerce/add-new-product'
    //     //     },
    //     //     {
    //     //       id: 'checkout',
    //     //       title: <FormattedMessage id="checkout" />,
    //     //       type: 'item',
    //     //       url: '/apps/e-commerce/checkout'
    //     //     }
    //     //   ]
    //     // }
    //   ]
    // }
  ]
};

export default academic;
