// assets
import {
  Bill,
  Calendar1,
  CalendarEdit,
  Diamonds,
  Kanban,
  KyberNetwork,
  Messages2,
  Profile2User,
  ShoppingBag,
  TimerStart,
  UserSquare,
  UserTag
} from 'iconsax-react';

// third-party
import { FormattedMessage } from 'react-intl';

// icons
const icons = {
  Academic: KyberNetwork,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  classes: Diamonds,
  attendance: CalendarEdit,
  students: UserTag,
  timetable: TimerStart
};

// ==============================|| MENU ITEMS - Academic ||============================== //

const general = {
  id: 'general',
  title: <FormattedMessage id="General" />,
  icon: icons.Academic,
  type: 'group',
  children: [
    {
      id: 'general-list',
      title: <FormattedMessage id="Academic Year" />,
      type: 'item',
      icon: icons.attendance,
      url: '/academic-year',
      breadcrumbs: true
    },
    {
      id: 'sc-list',
      title: <FormattedMessage id="Schools" />,
      type: 'item',
      icon: icons.attendance,
      url: '/schools',
      breadcrumbs: true
    },
    {
      id: 'bill-list',
      title: <FormattedMessage id="Billables" />,
      type: 'item',
      icon: icons.attendance,
      url: '/billables',
      breadcrumbs: true
    }
    // {
    //   id: 'teacher',
    //   title: <FormattedMessage id="Teacher" />,
    //   type: 'collapse',
    //   icon: icons.customer,
    //   children: [
    //     {
    //       id: 'teacher-list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/academics/teacher-list'
    //     },
    //     {
    //       id: 'customer-card',
    //       title: <FormattedMessage id="Lessaon Notes" />,
    //       type: 'item',
    //       url: '/apps/customer/customer-card'
    //     }
    //   ]
    // },
    // {
    //   id: 'students',
    //   title: <FormattedMessage id="Students" />,
    //   type: 'collapse',
    //   icon: icons.students,
    //   children: [
    //     {
    //       id: 'student-list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/academics/student-list',
    //       breadcrumbs: true
    //     }
    //     // {
    //     //   id: 'customer-card',
    //     //   title: <FormattedMessage id="Lessaon Notes" />,
    //     //   type: 'item',
    //     //   url: '/apps/customer/customer-card'
    //     // }
    //   ]
    // },
    // {
    //   id: 'classes',
    //   title: <FormattedMessage id="Classes" />,
    //   type: 'collapse',
    //   icon: icons.classes,
    //   children: [
    //     {
    //       id: 'student-list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/academics/classes',
    //       breadcrumbs: true
    //     },
    //     {
    //       id: 'customer-card',
    //       title: <FormattedMessage id="Subjects" />,
    //       type: 'item',
    //       url: '/academics/subjects'
    //     }
    //   ]
    // },
    // {
    //   id: 'attendance-list',
    //   title: <FormattedMessage id="Student Attendance" />,
    //   type: 'item',
    //   icon: icons.attendance,
    //   url: '/academics/attendance',
    //   breadcrumbs: true
    // },
    // {
    //   id: 'timetable-list',
    //   title: <FormattedMessage id="Timetable" />,
    //   type: 'item',
    //   icon: icons.timetable,
    //   url: '/academics/timetable',
    //   breadcrumbs: true
    // }

    // {
    //   id: 'class-list',
    //   title: <FormattedMessage id="Classes" />,
    //   type: 'item',
    //   icon: icons.classes,
    //   url: '/academics/classes',
    //   breadcrumbs: true
    // }
    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="Teachers" />,
    //   type: 'item',
    //   url: '/academics/teachers',
    //   icon: icons.chat,
    //   breadcrumbs: false
    // }
    // {
    //   id: 'calendar',
    //   title: <FormattedMessage id="calendar" />,
    //   type: 'item',
    //   url: '/apps/calendar',
    //   icon: icons.calendar
    // },
    // {
    //   id: 'kanban',
    //   title: <FormattedMessage id="kanban" />,
    //   type: 'item',
    //   icon: icons.kanban,
    //   url: '/apps/kanban/board'
    // },
    // {
    //   id: 'customer',
    //   title: <FormattedMessage id="customer" />,
    //   type: 'collapse',
    //   icon: icons.customer,
    //   children: [
    //     {
    //       id: 'customer-list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/apps/customer/customer-list'
    //     },
    //     {
    //       id: 'customer-card',
    //       title: <FormattedMessage id="cards" />,
    //       type: 'item',
    //       url: '/apps/customer/customer-card'
    //     }
    //   ]
    // },
    // {
    //   id: 'invoice',
    //   title: <FormattedMessage id="invoice" />,
    //   url: '/apps/invoice/dashboard',
    //   type: 'collapse',
    //   icon: icons.invoice,
    //   breadcrumbs: true,
    //   children: [
    //     {
    //       id: 'create',
    //       title: <FormattedMessage id="create" />,
    //       type: 'item',
    //       url: '/apps/invoice/create'
    //     },
    //     {
    //       id: 'details',
    //       title: <FormattedMessage id="details" />,
    //       type: 'item',
    //       url: '/apps/invoice/details/1'
    //     },
    //     {
    //       id: 'list',
    //       title: <FormattedMessage id="list" />,
    //       type: 'item',
    //       url: '/apps/invoice/list'
    //     },
    //     {
    //       id: 'edit',
    //       title: <FormattedMessage id="edit" />,
    //       type: 'item',
    //       url: '/apps/invoice/edit/1'
    //     }
    //   ]
    // },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.profile,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic'
    //     }
    //   ]
    // },

    // {
    //   id: 'e-commerce',
    //   title: <FormattedMessage id="e-commerce" />,
    //   type: 'collapse',
    //   icon: icons.ecommerce,
    //   children: [
    //     {
    //       id: 'products',
    //       title: <FormattedMessage id="products" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/products'
    //     },
    //     {
    //       id: 'product-details',
    //       title: <FormattedMessage id="product-details" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/product-details/1',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'product-list',
    //       title: <FormattedMessage id="product-list" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/product-list',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'add-new-product',
    //       title: <FormattedMessage id="add-new-product" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/add-new-product'
    //     },
    //     {
    //       id: 'checkout',
    //       title: <FormattedMessage id="checkout" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/checkout'
    //     }
    //   ]
    // }
  ]
};

export default general;
