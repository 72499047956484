import axios from 'axios';

const axiosServices = axios.create({ baseURL: 'https://highschool.backend.adesuapa.com/v1' });

// Set Bearer token for every request
axiosServices.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('serviceToken'); // Retrieve token from local storage or wherever it's stored
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handling unauthorized (401) responses
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && !window.location.href.includes('/login')) {
      window.location.pathname = '/login';
    }
    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;
