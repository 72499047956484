import { LOGIN, LOGOUT } from 'store/reducers/actions';
import { createContext, useEffect, useReducer } from 'react';

import Loader from 'components/Loader';
import PropTypes from 'prop-types';
import authReducer from 'store/reducers/auth';
import axios from 'utils/axios';
import { get } from 'lodash';

// constant
const initialState = {
  error: null,
  isLoggedIn: false,
  isInitialized: false,
  user: null
};

const setSession = (serviceToken) => {
  if (serviceToken) {
    localStorage.setItem('serviceToken', serviceToken);
    axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
  } else {
    localStorage.removeItem('ServiceToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const setUser = (user) => {
  if (user) {
    localStorage.setItem('user', JSON.stringify(user));
    axios.defaults.headers.common.Authorization = `User ${user}`;
  } else {
    localStorage.removeItem('user');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ==============================|| FROG CONTEXT & PROVIDER ||============================== //

const AdesuapaAuthContext = createContext(null);

export const AdesuapaAuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);

  useEffect(() => {
    const init = async () => {
      try {
        const serviceToken = localStorage.getItem('serviceToken');
        if (serviceToken) {
          setSession(serviceToken);
          const response = await axios.get('/profile');
          const user = response.data.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          setSession(null);
          dispatch({
            type: LOGOUT,
            payload: {
              isLoggedIn: false,
              user: null
            }
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    init();
  }, []);

  /**
   * Logs in a user with the provided email and password.
   * @param {string} email - The email of the user.
   * @param {string} password - The password of the user.
   * @returns {Promise<Object>} - A promise that resolves to the response data.
   */
  const login = async (email, password) => {
    const response = await axios.post('/login', { email, password });
    const user = response.data;

    const userToken = get(user, 'data.access_token', '');

    setSession(userToken);
    setUser(user);

    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user
      }
    });

    return response.data;
  };

  /**
   * Logs out the user.
   */
  const logout = () => {
    setSession(null);

    setUser(null);
    dispatch({
      type: LOGOUT,
      payload: {
        isLoggedIn: false,
        user: null
      }
    });
  };

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return (
    <AdesuapaAuthContext.Provider
      value={{
        ...state,

        login,

        logout
      }}
    >
      {children}
    </AdesuapaAuthContext.Provider>
  );
};

AdesuapaAuthProvider.propTypes = {
  children: PropTypes.node
};

export default AdesuapaAuthContext;
