import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

const initialState = {
  studentInfo: {
    first_name: '',
    last_name: '',
    other_name: '',
    date_of_birth: '',
    religious_affiliation: '',
    admission_date: dayjs().format('YYYY-MM-DD'),
    admission_fee_amount: null,
    admission_fee_amount_paid: null,
    admission_fee_received_by: '',
    admission_fee_paid_by: '',
    gender: 'male',
    nationality: '',
    language: '',
    class_admitted: '',
    term_admitted: '',
    academic_year_admitted: '',
    blood_group: 'A positive',
    wears_glasses: false,
    height: 0,
    weight: 0,
    needs_regular_checkups: false,
    address: '',
    profile_picture_location: '',
    unit_of_measurement: 'metric'
  },

  parentInfo: {
    first_name: '',
    last_name: '',
    other_name: '',
    phone_1: '',
    email: '',
    address: '',
    occupation: '',
    relationship_to_child: '',
    second_parent_name: '',
    phone_2: ''
  }
};

// ==============================|| SLICE - STUDENT ||============================== //

const students = createSlice({
  name: 'students',
  initialState,
  reducers: {
    updateStudentInfo: (state, action) => {
      state.studentInfo = { ...state.studentInfo, ...action.payload };
    },
    updateParentInfo: (state, action) => {
      state.parentInfo = { ...state.parentInfo, ...action.payload };
    }
  }
});

export default students.reducer;

export const { updateStudentInfo, updateParentInfo } = students.actions;
