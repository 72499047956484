import academic from './academic';
// import admissions from './admissions';
// project-imports
// import applications from './applications';
// import chartsMap from './charts-map';
import dashboard from './dashboard';
import general from './general';
// import formsTables from './forms-tables';
// import pages from './pages';
// import support from './support';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, academic, general]
};

export default menuItems;
